@import "../../style/_variables";
@import "../../style/_viewports";

.desktop-downloads {
  display: flex;

  @include viewports(up-to small) {
    flex-direction: column;
  }

  &__img {
    @include viewports(up-to small) {
      order: 1;
    }
  }

  &__items {
    min-width: 350px;
    margin-right: 15px;

    @include viewports(up-to small) {
      min-width: 100%;
      order: 2;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0 2px 10px rgba(#000, 0.15);
      margin-bottom: 15px;
      padding: 30px;
      text-decoration: none !important;

      &:hover {
        background: #fafafa;
      }

      &__title {
        font-size: 2.4rem;
        color: $color-green;
        font-weight: bold;
      }

      &__img {
        width: 40px;
        margin: 10px 0;
      }

      &__label {
        font-weight: 300;
        color: $color-green;
        font-size: 1.6rem;
      }

      &__checksum {
        font-size: 1rem;
        color: rgba($color-black, 0.5);
        padding: 5px 10px;
        margin-top: 10px;
        background: rgba($color-black, 0.1);
        border-radius: 5px;
        text-align: center;
        max-width: 100%;
        word-break: break-all;

        span {
          display: block;
          font-weight: bold;
          color: $color-black;
        }
      }

      a {
        text-decoration: none !important;
        min-width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  &__img {
    flex: 1;

    @include viewports(up-to tiny) {
      margin-top: 10px;
    }

    img {
      width: 100%;
    }
  }
}