@import "../../style/_variables";
@import "../../style/_viewports";

.ResourcesPage {
  h3.title {
    padding: 0;
    margin-top: 0;
    font-weight: normal;

    & + .blocks-list {
      margin-top: 15px;
    }
  }
}

.text-sections {
  .title {
    font-weight: 500;
    font-size: 3rem;
  }

  p {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  .row {
    margin-top: 50px;
  }

  ul {
    li {
      padding: 5px;
    }
  }

  a:not(.btn) {
    color: $color-green;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}

.description {
  color: $color-green;
  font-style: italic;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 2.3rem;
}

.blocks-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;

  @include viewports(up-to tiny) {
    margin-left: -20px;
    margin-right: -20px;
  }

  &--grey {
    .blocks-list__block {
      background: #737373;
    }

    .blocks-list__block__img {
      width: 80px;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 180px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(#000, 0.2);
    padding: 20px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 15px;
    transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);

    @include viewports(up-to tiny) {
      width: 138px;
      padding: 11px;
    }

    &:hover {
      transform: rotate(-2deg);
    }

    &__title {
      color: $color-green;
      margin: 0;
      font-weight: 300;
      font-size: 1.7rem;
      text-align: center;
    }

    &__img {
      width: 45px;
      margin: 20px 0;
    }

    &__type {
      color: $color-green;
      margin: 0;
    }

    a {
      color: $color-green;
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 4px 0;
      transition: all 0.2s linear;

      &:hover {
        color: #fff;
      }
    }
  }
}