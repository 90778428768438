@import "../../style/_variables";
@import "../../style/_viewports";

.Loader {
  &.popup-status {
    &-enter {
      opacity: 0;
      pointer-events: none;
  
      .Loader__window {
        transform: scale(0.95);
      }
    }
  
    &-enter-done {
      opacity: 1;
      transition: opacity 0.2s;
      pointer-events: all;
  
      .Loader__window {
        transform: scale(1);
      }
    }
  
    &-exit {
      opacity: 1;
      pointer-events: all;
  
      .Loader__window {
        transform: scale(1);
      }
    }
  
    &-exit-done {
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
  
      .Loader__window {
        transform: scale(0.95);
      }
    }
  }
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.68);
    z-index: 1;
    transition: opacity 0.5s linear;
  }

  &__window {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: #fff;
    position: relative;
    z-index: 2;
    transform: scale(0.95);
    transition: all 0.5s cubic-bezier(0,.7,.4,1);

    &__spinner {
      width: 30px;
      height: 30px;
      border: 2px solid $color-green-dark;
      border-right-color: rgba(0,0,0,0);
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}