@import "../../style/_variables";
@import "../../style/_viewports";

.latest-news {
  display: flex;
  justify-content: space-between;

  @include viewports(up-to tiny) {
    flex-direction: column;
    align-items: center;
  }

  @include viewports(up-to small) {
    flex-wrap: wrap;
    justify-content: center;
  }

  .post {
    display: block;
    max-width: 370px;
    border-radius: 6px;
    box-shadow: 0 20px 32px -5px rgba(15, 39, 61, 0.12);
    transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);

    &__tag-container {
      margin: 10px 5px;
      text-align: center;

      &__tag {
        background: $color-green;
        padding: 5px 10px;
        border-radius: 6px;
        width: fit-content;
        display: inline-block;
        margin: 5px;
        font-size: 1rem;
    
        a {
          text-transform: uppercase;
          color: white;
        }
    
        &:hover {
          transform: translateY(-2px);
        }
      }
    }

    &__published {
      text-align: center;
      color: black;
      font-size: 1rem;
      color: $color-green;
    }
  
    hr {
      border: 0;
      border-bottom: 1px solid $color-green;
      background: none;
      margin: 0;
      margin-top: 15px;
    }

    a {
      text-decoration: none;
    }

    @include viewports(up-to tiny) {
      max-width: 90%;
      min-width: 90%;
      margin: 0;
      margin-bottom: 20px;
    }

    @include viewports(up-to small) {
      margin: 20px 5px;
    }
    
    &:hover {
      box-shadow: 0 20px 32px -3px rgba(15, 39, 61, 0.12), 0 32px 72px -20px rgba(15, 39, 61, 0.17), 0 40px 100px 0 rgba(9, 24, 37, 0.09);
      transform: translateY(-4px);
    }

    &__img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 6px 6px 0 0;
      border-bottom: 1px solid $color-green;
    }

    &__title {
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      color: $color-green;
      margin-top: 20px;
      padding: 0 15px;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 45px;
      text-align: center;
      font-size: 1.6rem;
      margin: 20px auto;
      color: $color-green;
      text-decoration: none;
      transition: background 0.2s linear;

      &:hover {
        background: rgba($color-green, 0.1);
        border-radius: 4px;
      }
    }
  }
}